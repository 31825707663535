@use "../../stylesheets/defaults";

.page {
  background-color: defaults.$color_tour;
  min-height: 100vh;
  padding-bottom: 100px;
}

.mainTitle {
  text-align: center;
  padding: 200px 0px 80px 0px;
}

.border {
  border: defaults.$black;
  border-width: 0px 0px 3px 0px;
  border-style: solid;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.title {
  font-size: 2.5rem;
  line-height: 2.75rem;
  padding-left: 0px;
}

@media (max-width: 768px) {
  .title {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-left: 15px;
  }
}

.crossholder svg {
  width: 40px;
  transition: all 0.1s ease-in;
}

.rotate svg {
  transform: rotateZ(45deg);
  stroke-width: 1.2;
}

@media (max-width: 768px) {
  .crossholder svg {
    width: 1.4em;
  }
}

.content {
  padding-right: 20px;
  padding-left: 0px;
}

.content p {
  font-size: 1.5rem;
  line-height: 1.6rem;
}

@media (max-width: 768px) {
  .content {
    padding-top: 12px;
    padding-left: 15px;
  }

  .content p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.bottom_buttons {
  margin-top: 30px;
}

.backbutton {
  border: defaults.$black;
  border-width: 2px;
  border-style: solid;
  background-color: defaults.$color_tour;
}

@media (max-width: 768px) {
  .backbutton {
    font-size: 1rem;
  }

  .backbutton svg {
    height: 1rem;
  }
}

.reel {
  margin-bottom: 5px;
}

.specs {
  margin-top: 1rem;
}

.imageHolder {
}
