:local(.page){
  background-color: #f1f1f2;
  min-height: 100vh;
  padding-bottom: 100px;
}

:local(.border){
  border: #333;
  border-width: 0px 0px 3px 0px;
  border-style: solid;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  :local(.borderholder){
    padding-right: 0px
  }
}

:local(.imageHolder){
  display: block;
  width: 100%;
}

:local(.mainTitle){
  text-align: center;
  padding: 200px 0px 80px 0px;
}

:local(.title){
  font-size: 2.5rem;
  line-height: 2.75rem;
  padding-left: 0px;
}

@media (max-width: 768px) {
  :local(.title){
    font-size: 1rem;
    line-height: 1.5rem;
    padding-left: 15px;
  }
}

:local(.moment){
  font-size: 1.2rem;
}

:local(.address){
  margin-top: 75px;
  white-space: pre-line;
  font-size: 1.2rem;
  line-height: 1.25rem;
}

:local(.button){
  background-color: #333;
  color: #ffe6aa;;
  font-size: 1rem;
  padding: 5px 7px 0px 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

:local(.crossholder svg){
  width: 40px;
  transition: all .1s ease-in;
}

:local(.rotate svg) {
  transform: rotateZ(45deg);
  stroke-width: 1.2;
}

@media (max-width: 768px) {
  :local(.crossholder svg){
    width: 1.4em;
  }
}


@media (max-width: 768px) {
  .infobutton {
    margin-left: auto;
  }
}


:local(.content){
  padding-right: 20px;
  padding-left: 0px;
}

:local(.content p){
  font-size: 1.5rem;
  line-height: 1.6rem;
}

@media (max-width: 768px) {
  .content{
    padding: 7px 15px 0px 15px;
  }

  .content p {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}
