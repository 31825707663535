:local(.footerHolder) {
  position: fixed;
  height: 50px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, .85);
  width: 100%;
  padding-top: 5px;
  z-index: 10;
}

:local(.instagram) {
  margin-top: 7px;
  height: 30px;
}

:local(.loc) {
  margin-top: 7px;
  height: 30px;
  padding-left: 15px;
}

@media (min-width: 769px) {
  :local(.loc){
    display: none !important;
  }
}

:local(.lineConent) {
  font-size: 0.75rem;
  line-height: 0.85rem;
  margin-top: 10px;
}

:local(.linkHolder) {
  margin-left: 10px;
}

:local(.linkBlock){
  padding-right: 40px;
  min-width: 200px;
}


:local(.openingBlock){
  padding-right: 20px;
  width: 170px;
}

:local(.searchboxHolder){
  padding-right: 50px;
  margin-left: 60px;
  margin-top: 6px;
}

:local(.langPickerHolder) {
  width: 45px;
  font-size: 27px;
  margin-top: 3px;
}

:local(.langPickerElement) {
}


@media (max-width: 768px) {
  :local(.openingHolder){
    display: none !important;
  }

  :local(.linkHolder) {
    display: none !important;
  }

  :local(.searchboxHolder){
    padding-right: 0px;
    margin-left: 0px;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) {
  :local(.hide){
    display: none !important;
  }
}

.searchlink img {
  height: 27px;
}
