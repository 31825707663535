:local(.lightbox){
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent;
  z-index: 1000;
}

:local(.content){
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: none;
  background: transparent;
  overflow: hidden;
  border-radius: 0px;
  outline: none;
  padding: 0px;
}


:local(.outer){
  background-color: rgba(0, 0, 0, .85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

:local(.frame){
  height: 100vh;
  text-align: center;

  transition: opacity 400ms ease 0s;
  animation-duration: 400ms;
  animation-direction: reverse;

  animation-name: openWindow;
}


:local(.imgg){
  max-width: calc(100vw - 180px);
  max-height: calc(100vh - 75px);
  padding-top: 10px;
  user-select: none;
}

@media (max-width: 768px) {
  :local(.imgg){
    max-width: calc(100vw - 20px);
  }
}


:local(.imageHolder) {
  height: calc(100vh - 40px);
}

:local(.text){
  font-size: 1.5rem;
  line-height: 1.6rem;
  margin: 0px 10px 20px;
  color: white;
}

@media (max-width: 768px) {
  :local(.text){
    font-size: 1rem;
    letter-spacing: normal;
    line-height: 1.2rem;
    margin: 0px 10px 20px;
  }
}

.buttonHolder{
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 20px;
  color: white;
}


:local(.buttonHolder div){
  margin-bottom: 25px;
}

.buttonHolder div svg, .buttonHolder div img{
  width: 45px;
}

@media (max-width: 768px) {
  .buttonHolder{
    padding: 10px;
  }

  .buttonHolder div svg, .buttonHolder div img{
    width: 31px;
  }
}


@media (max-width: 768px) {
  :local(.zoom) {
    display: none;
  }
}

:local(.bgimg){
  transition: background 500ms linear;
}

:local(.navholder){
  margin-left: 70px;
  display: inline-block;
}
@media (max-width: 768px) {
  :local(.navholder){
    margin-left: 0px;
    display: block;
  }
}

:local(.subnav){
  padding: 0px 10px;
  display: inline-block;
}



@keyframes openWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
