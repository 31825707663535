.textHolder {
  opacity: 0;
}

.enterHolder {
  position: absolute;
  bottom: 5%;
  width: 100%;
  text-align: center;
  opacity: 0;
}

.enter {
  display: inline-block;
  background-color: #f1f1f1;
  padding: 8px 10px 6px 12px;
  border-radius: 8px 8px 8px 8px;
}

.text {
  display: inline-block;
  letter-spacing: -0.05em;
  padding-right: 14px;
  font-size: 2rem;
  line-height: 2rem;
  color: #444;
}

.arrowHolder {
  font-size: 2.5rem;
  line-height: 2rem;
  display: inline-block;
}

.showconent {
  opacity: 100;
  transition: opacity 2000ms;
}

.video {
}
