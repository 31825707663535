$text: #000;
$border: #000;
$background: #fff;

$fontsize: 1.4rem;

.orderwindow {
  background-color: $background;
  min-height: 100vh;
  padding: 130px 25px 80px 25px;
}

.orderform {
  border-right: 2px solid $border;
}

@media (max-width: 768px) {
  .orderform {
    border-right: 0px;
    padding-right: 0px;
  }
}

.subwindow {
  margin-top: 40px;
}

.formwindow {
  margin-top: 20px;
  padding-right: 30px;
}

@media (max-width: 768px) {
  .formwindow {
    padding-right: 0px;
  }
}

.infowindow {
  padding-left: 35px;
}

@media (max-width: 768px) {
  .infowindow {
    padding-left: 15px;
    padding-right: 0px;
  }
}

.header {
  color: $text;
  font-size: 3rem;
  margin-bottom: 20px;
  letter-spacing: -0.2rem;
}

@media (max-width: 768px) {
  .header {
    font-size: 2rem;
    margin-bottom: 0px;
  }
}

.input {
  background-color: transparent;
  border: 0px;
  width: 100%;
  border-bottom: 2px solid $border;
  font-size: $fontsize;
  color: $text;
  margin-bottom: 15px;
  border-radius: 0;
  padding: 0px;
  padding-left: 5px;
}

.input::placeholder {
  color: $text;
}

.input:focus::placeholder {
  color: transparent;
}

.input:focus,
.textarea:focus {
  outline: none;
  color: $text;
  border-color: $border;
}

.select {
  padding-left: 0px;
}

.select option {
  font-size: 1rem;
  color: #333;
}

.textarea {
  color: $text;
  background-color: $background;
  border: 2px solid $border;
  width: 100%;
  min-height: 120px;
  font-size: $fontsize;
  margin-bottom: 18px;
  margin-top: 10px;
  border-radius: 0;
  padding-left: 3px;
}

.formlabel {
  color: $text;
  font-size: $fontsize;
  padding-left: 3px;
}

.notes {
  margin-top: 20px;
}

.notes p {
  font-size: 1.2rem;
  color: $text;
}

.notes a {
  color: $text;
  text-decoration: underline;
}

.warning {
  float: left;
  color: red;
  font-size: $fontsize;
}

.image {
}
