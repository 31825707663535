:local(.contactwindow){
  background-color: #b4d2e6;
  min-height: 100vh;
  padding: 130px 25px 80px 25px;
}

:local(.subwindow){
  margin-top: 40px;
}

:local(.contactform){
  border-right: 2px solid #333334;
}

@media (max-width: 768px) {
  :local(.contactform){
    border-right: 0px solid #333334;
  }
}

:local(.header) {
  font-size: 3rem;
  margin-bottom: 30px;
  letter-spacing: -0.2rem;
}

:local(.subheader) {
  font-size: 1.5rem;
  line-height: 1.6rem;
}

@media (max-width: 768px) {
  :local(.header) {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

  :local(.subheader) {
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
}

:local(.formwindow){
  margin-top: 40px;
  padding-right: 30px;
}

@media (max-width: 768px) {
  :local(.formwindow){
    padding-right: 0px;
  }
}

.input {
  background-color:  #b4d2e6;
  border: 0px;
  width: 100%;
  border-bottom: 2px solid #333334;
  font-size: 1.5rem;
  color: #333;
  padding-bottom: 0px;
  margin-bottom: 18px;
  border-radius: 0;
  padding: 0px;
}

.input::placeholder{
  color: #333;
}

.input:focus::placeholder{
  color: transparent;
}

:local(.input:focus){
  outline: none;
}

:local(.textarea:focus){
  outline: none;
}

:local(.question){
  font-size: 1.5rem;
}

:local(.textarea){
  background-color:  #b4d2e6;
  border: 2px solid #333334;
  width: 100%;
  height: 200px;
  font-size: 1.5rem;
  margin-bottom: 10px;
  border-radius: 0;
}

:local(.routewindow) {
  padding-left: 20px;
}

:local(.mapholder){
  margin-top: 26px;
  margin-bottom: 20px;
  border: 2px solid #333334;
  padding: 0px;
}


.error {
  font-weight: bold;
  color: #d41717;
}

.contacttext {
  padding-bottom: 10px;
}

.contacttext p {
  font-size: 1.5rem;
  line-height: 1.6rem;
}

.contacttext p a {
  text-decoration: underline;
}
