
:local(.logoHolder){
  padding-top: 3vh;
  padding-left: 1.5vw;
  height: 15vh;
  opacity: 0;
}

:local(.logoFull) {
  height: 10vh;
}

@media (min-width: 1000px) {
  :local(.logoFull) {
    height: 10vh;
    display: block
  }
}

@media (max-width: 1000px) {
  :local(.logoFull) {
    height: 6vh;
  }

  :local(.logoHolder){
    height: 10vh;
  }
}

@media (max-width: 768px) {
  :local(.logoHolder){
    display: none;
  }
}

/* ------- */

:local(.bigText){
  font-size: 6vw;
  line-height: 1;
  display: block;
  letter-spacing: -0.3vw;
}

@media (max-width: 768px) {
  :local(.bigText){
    font-size: 14vw;
    letter-spacing: -0.7vw;
  }
}

/* STD */

:local(.std){
  position: absolute;
  top: 10vh;
  width: 24vw;
  height: 24vw;
  border-radius: 12vw 6vw 12vw 12vw;
  z-index: 10;
  opacity: 0;
  background-size: 24vw 24vw;
}

@media (max-width: 768px) {
  :local(.std){
    top: 40vh;
    left: 10vh;
    width: 40vw;
    height: 40vw;
    border-radius: 20vw 10vw 20vw 20vw;
    background-size: 40vw 40vw;
  }
}

/* Leftside */

:local(.leftSide){
  /*height: 85vh;*/
  border-radius: 0px 12vw 0px 0px;
  margin: 0px 25px 0px 60px;

  /*opacity: 0;*/
  height: 0vh;
  overflow: hidden;
}

:local(.leftSideShow){
  height: 85vh;
  padding: 20px 20px 10px 20px;
  transition: height 2000ms, padding 500ms;
}

@media (max-width: 768px) {
  :local(.leftSide){
    height: 0vh;
    border-radius: 0px 25vw 0px 0px;
    margin: 0px 15px 0px 0px;
  }

  :local(.leftSideShow){
    height: 50vh;
    padding: 20px 20px 10px 20px;
    transition: height 2000ms, padding 500ms;
  }
}

:local(.leftContentHolder){
  width: 100%;
  opacity: 0;
}

@media (max-width: 768px) {
  :local(.leftContentHolder){
    height: 100%;
  }
}

:local(.leftSideH1){
  display: block;
  position: relative;
}

:local(.leftSideArrow){
  position: absolute;
  bottom: 5px;
  right: 0;
  width: 3vw;
}
:local(.leftSideArrowSmall){
  display: none;
}

@media (max-width: 768px) {
  :local(.leftSideArrow){
    display: none;
  }
  :local(.leftSideArrowSmall){
    display: flex;
    width: 5vw;
    position: absolute;
    right: 22px;
    bottom: 8px;
  }
}

:local(.about){
  height: 3.33vh;
  padding-right: 40px;
}

/* RightSide */

:local(.rightSideArrowHolder){
  opacity: 0;
}

:local(.rightSideArrow){
  width: 3vw;
}

:local(.rightSideArrowSmall){
  display: none;
}

@media (max-width: 768px) {
  :local(.rightSideArrow){
    display: none;
  }
  :local(.rightSideArrowSmall){
    display: flex;
    width: 5vw;
    position: absolute;
    right: 22px;
    top: 28px;
  }
}

:local(.rightSide){
  margin-left: 0px;
  height: 0vh;
  min-height: 0vh;
  border-radius: 0px 12vw 0px 0px;
  margin: 0px 50px 0px 0px;
  overflow: hidden;
}

:local(.rightSideShow){
  min-height: 85vh;
  padding: 20px;
  transition: min-height 2000ms, padding 500ms;
}

@media (max-width: 1000px) {
  :local(.rightSide){
    min-height: 90vh;
  }
}

:local(.rightSideH1){
  margin: 0px 0px 30px 0px;
  opacity: 0;
}

@media (max-width: 768px) {
  :local(.rightSide){
    height: 0vh;
    min-height: 0vh;
    border-radius: 25vw 0px 0px 0px;
    margin: 20px 15px 0px 15px;
  }

  :local(.rightSideShow){
    min-height: 50vh;
    padding: 20px;
    transition: min-height 2000ms, padding 500ms;
  }

  :local(.rightSideH1){
    margin: 0px 0px 50px 15px;
  }
}

/* Colab */

:local(.colab){
  text-align: right;
  margin: 20px 25px 0px 40px;
  opacity: 0;
}

@media (max-width: 768px) {
  :local(.colab){
    display: none;
  }
}



:local(.showcontent) {
  opacity: 100;
  transition: opacity 2000ms;
}
