.react-autosuggest__container {
  position: relative;
}

.topsearch .react-autosuggest__input {
  width: 280px;
  padding-left: 10px;
  padding-top: 5px;
  height: 25px;
  // padding: 10px 20px;
  /*font-weight: 300;*/
  font-size: 1rem;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 0px 10px 0px 0px;
  font-family: HelveticaNeueeTextPro-Bold;
}

.botsearch .react-autosuggest__input {
  // width: 280px;
  // height: 30px;
  // padding: 10px 20px;
  // /*font-weight: 300;*/
  // font-size: 16px;
  // border: 1px solid rgb(51, 51, 51);
  // font-family: HelveticaNeueeTextPro-Bold;

  color: #333;
  width: 500px;
  padding: 5px 10px 0px 10px;
  font-size: 2rem;
  border: 2px solid rgb(51, 51, 51);
  border-radius: 0px 20px 0px 0px;
  font-family: HelveticaNeueeTextPro-Bold;
}

.cloudsearch .react-autosuggest__input {
  color: #333;
  width: 500px;
  padding: 5px 10px 0px 10px;
  font-size: 2rem;
  border: 2px solid rgb(51, 51, 51);
  border-radius: 0px 20px 0px 0px;
  font-family: HelveticaNeueeTextPro-Bold;
  // background-color: #8bffec;
}
.cloudsearch .react-autosuggest__input::placeholder {
  // color: #333;
}

@media (max-width: 768px) {
  .cloudsearch .react-autosuggest__input {
    font-size: 1rem;
    width: 80vw;
  }
}


.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

// .react-autosuggest__suggestions-container {
//   display: none;
// }

.topsearch .react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 31px;
  width: 280px;
  border: 1px solid rgb(51, 51, 51);
  background-color: #fff;
  font-size: 1rem;
  z-index: 1000;
}

.botsearch .react-autosuggest__container--open .react-autosuggest__suggestions-container {
  // display: block;
  // position: absolute;
  // top: 51px;
  // width: 280px;
  // border: 1px solid rgb(51, 51, 51);
  // background-color: #fff;
  // font-size: 16px;
  // z-index: 1000;
  // transform: translateY(-100%);
  // margin-top: -60px
  display: block;
  position: relative;
  // top: 61px;
  width: 280px;
  border: 1px solid rgb(51, 51, 51);
  background-color: #fff;
  font-size: 1rem;
  z-index: 1000;
}

.cloudsearch .react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 61px;
  width: 280px;
  border: 1px solid rgb(51, 51, 51);
  background-color: #fff;
  font-size: 1rem;
  z-index: 1000;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 0px;
  margin: 0px 20px;
  border-bottom: 2px solid rgb(51, 51, 51);
}

.react-autosuggest__suggestion:last-child {
  margin: 0px 0px;
  padding: 5px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
}

.react-autosuggest__section-title{
  padding: 10px 10px;
  border-bottom: 2px solid rgb(51, 51, 51);
  z-index: 1000;
  /*font-weight: bold;*/
}

.react-autosuggest__section-title:not(:first-child) {
  /*border-top: 1px solid #ddd;*/
}

.react-autosuggest__suggestion--focused {
  background-color: #0C7EAF;
  color: #fff;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}


.react-autosuggest__suggestions-container {
  max-height: 80vh;
  overflow-y: auto;
  text-align: left;
}

.cloudsearch .react-autosuggest__suggestions-container {
  max-height: 30vh;
  font-family: HelveticaNeueeTextPro-Roman;
}

/*splashpage*/

.splashpage_content p {
    font-size: 7vw;
    line-height: 1;
    letter-spacing: -0.3vw;
}

.splashpage_subcontent p {
  font-size: 2vh;
}


.link {
  cursor: pointer;
  user-select: none;
}

.content {
  padding: 0px 20px;
}

@keyframes line1-in {
  0% { transform: translate(0, 0); }
  30% { transform: translate(0px, 22.5px); }
  70% {
    transform-origin: 27.5px 27.5px;
    transform: translate(-17.5px, 17.5px) rotate(55deg) scale(1.3,1);
  }
  100% {
    transform-origin: 27.5px 27.5px;
    transform: translate(-17.5px, 17.5px) rotate(45deg) scale(1.3,1);
  }
}

@keyframes line2-in {
  0% { transform: rotate(0deg); }
  30% { transform: rotate(0deg); }
  70% {
    transform-origin: 27.5px 27.5px;
    transform: rotate(-55deg) scale(1.3,1);
  }
  100% {
    transform-origin: 27.5px 27.5px;
    transform: rotate(-45deg) scale(1.3,1);
  }
}

@keyframes line3-in {
  0% { transform: translate(0, 0); }
  50% { transform: translate(0, 21px); }
  100% { transform: translate(0, 21px); }
}

@keyframes line1-out {
  0% { transform: rotate(45deg) scale(1.3,1) }
  70% { transform: translate(0px, 22.5px); }
  100% { transform: translate(0, 0); }
}

@keyframes line2-out {
  0% { transform: rotate(-45deg) scale(1.3,1); }
  70% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
}

@keyframes line3-out {
  0% { transform: translate(0, 25px); }
  80% { transform: translate(0, 25px); }
  100% { transform: translate(0, 0); }
}

$speed: 0.2s;
$speedout: 0.41s;

.closed #hamburger .line1 {
  animation: $speedout ease-out 0s 1 forwards line1-out;
}
.closed #hamburger .line2 { transform-origin: center; animation: line2-out forwards $speed ease-out;}
.closed #hamburger .line3 { animation: line3-out forwards $speedout ease-out;}

.opened #hamburger .line1{ animation: line1-in forwards $speed ease-out; }
.opened #hamburger .line2{ transform-origin: center; animation: line2-in forwards $speed ease-out; }
.opened #hamburger .line3{ animation: line3-in forwards $speed ease-out;}


/* Style scrollbar elements */

// ::-webkit-scrollbar {
//   height: 12px;
//   width: 14px;
//   background: transparent;
//   z-index: 12;
//   overflow: visible;
// }

// ::-webkit-scrollbar-thumb {
//   width: 10px;
//   background-color: #434953;
//   border-radius: 10px;
//   z-index: 12;
//   border: 4px solid rgba(0, 0, 0, 0);
//   background-clip: padding-box;
//   transition: background-color 0.3s ease-in-out;
//   margin: 4px;
//   min-height: 32px;
//   min-width: 32px;
//   }

// ::-webkit-scrollbar-thumb:hover {
//   background: #4E5157;
// }

// ::-webkit-scrollbar-corner {
//   background: #202020;
// }

