:local(.fullscreenButton) {
  margin: 5px 5px;
  padding: 5px 5px;
}

.title {
  font-size: 1.5rem;
  line-height: 1.6rem;
}

@media (max-width: 768px) {
  .title {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

:local(.subnavholder){
  margin-top: 10px;
}

:local(.subnav){
  padding: 0px 10px;
}

:local(.bgimg){
  transition: background 500ms, width 500ms, height 500ms
}
