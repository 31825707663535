.header {
  text-align: center;
  font-size: 5em;
  line-height: 66px;
  padding-top: 25vh;
  padding-bottom: 70px;
  letter-spacing: -0.05em;
}

.header_wac {
  color: black;
  padding: 25vh 20vw 40px 20vw;
  font-size: 5rem;
  line-height: 5.5rem;
  text-transform: uppercase;
  letter-spacing: -0em;
}

.content {
  padding: 0px 30vw 100px 30vw;
}

.content_white {
  color: black;
  padding-bottom: 100px;
}

.video {
  padding-bottom: 20px;
}

.contain_width {
  padding-left: 20vw;
  padding-right: 20vw;
}

.content_white p {
  color: black;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1.6rem;
}

.content_white p a {
  color: black;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .header {
    font-size: 2em;
    line-height: 1.1em;
    letter-spacing: -0.05em;
    padding-bottom: 50px;
  }

  .content {
    padding: 0px 20px 100px 20px;
  }

  .contain_width {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content_white p {
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 1.2rem;
  }
}

.content p {
  font-size: 1.05rem;
  line-height: 1.3rem;
}

//** press **//

.halfhalf {
  padding-top: 15vh;
  margin: 0px 2vw 0px 0vw;
}

@media (max-width: 768px) {
  .halfhalf {
    padding-top: 130px;
    margin: 0px;
  }
}

.header_half {
  text-align: left;
  padding-top: 0px;
  padding-bottom: 1.5rem;
  font-size: 4em;
}

.halfholder {
  padding: 10vh 20px;
  min-height: 75vh;
}

@media (max-width: 768px) {
  .halfholder {
    min-height: 0px;
    padding: 20px;
  }
}

.halfside {
  border-left: 2px solid #333;
  padding-left: 0px;
}

@media (max-width: 768px) {
  .halfside {
    border-left: 0px;
    padding-left: 15px;
  }
}

$text: #333;
$border: #333;

$fontsize: 1.4rem;

.input,
.input:focus {
  background-color: transparent;
  border: 0px;
  width: 100%;
  border-bottom: 2px solid $border;
  font-size: $fontsize;
  color: $text;
  margin-bottom: 20px;
  border-radius: 0;
  padding: 0px;
  padding-left: 5px;
  font-weight: bold;
}

.input::placeholder {
  color: $text;
  font-weight: normal;
}

.input:focus::placeholder {
  color: transparent;
}

.pressgeneral p {
  font-size: 1.2em;
  line-height: 1.3em;
}

.event_holder {
  margin-bottom: 5rem;
}

.event_title {
}

.event_subtitle {
  font-size: 1.2em;
  line-height: 1.3em;
  margin-bottom: 0px;
}

.bottom_buttons {
}

.bottom_buttons {
}
