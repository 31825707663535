.page {
  background-color: #8bffec;
  min-height: 100vh;
  padding-bottom: 100px;
}

.mainTitle{
  text-align: center;
  padding: 200px 0px 80px 0px;
}

@media (max-width: 768px) {
  .mainTitle{
    padding-bottom: 20px;
  }
}

.searchBoxHolder {
  text-align: center;
  padding: 0px 15vw;
}

@media (max-width: 768px) {
  .searchBoxHolder {
    padding: 0px 0px;
  }
}

.advancedHolder {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.openAdvanced {
  max-height: 80vh;
  transition: max-height 0.5s ease-in;
}

.advancedHeader {
  margin: 0px 0px;
  text-align: left;
  text-transform: lowercase;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .advancedHeader {
    text-align: center;
  }
}

.headerTitle {
  margin: 0px 0px 15px 0px;
  padding: 5px 0px;
  border-width: 2px 0px;
  border-style: solid;
  border-color: #333;
}

.advancedToggle {
  width: 100%;
  text-align: right;
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  border-color: #333;
  padding-bottom: 15px;
  text-transform: lowercase;
}

.advancedToggleButton {
  border-radius: 0px 8px 0px 0px;
  padding-right: 15px;
}

@media (max-width: 768px) {
  .advancedToggleButton {
    font-size: 1rem;
    padding: 6px 8px 3px 3px;
  }
}

.inputholder {
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  border-color: #333;
  padding: 15px 0px;
}

.searchbox {
  color: #333;
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 5px 10px 0px 10px;
  font-size: 2rem;
  border: 2px solid rgb(51, 51, 51);
  font-family: HelveticaNeueeTextPro-Bold;
}

@media (max-width: 768px) {
  .searchbox {
    font-size: 1rem;
    height: 2.5rem;
  }
}

.searchbox:focus {
  outline: none;
}

.toggle {

}

.tag {
  font-size: 1.5rem;
  display: inline-flex;
  padding: 0px 8px 0px 3px;
  border-style: solid;
  border-radius: 0px 10px 0px 0px;
  border-width: 1px;
  overflow: hidden;
  margin-bottom: 6px;
}

.tag svg {
  height: 1rem;
  vertical-align: baseline;
  padding-left: 3px;
  padding-right: 5px;
  stroke-width: 3px;
  margin-top: 0.6rem;
}

@media (max-width: 768px) {
  .tag {
    font-size: 1.1rem;
  }
  .tag svg {
    height: 0.8rem;
  }
}

.sectionTitle {
  margin-top: 20px;
  font-size: 2rem;
}

.suggestion {
  cursor: pointer;
}


.noresults {
  font-size: 2rem;
}

.full {
  width: 100%;
}

.full th {
  font-weight: bold;
  font-size: 1.2rem;
}

.resultsHolder, .noresults {
  margin-top: 20px;
}
