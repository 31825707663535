:local(.page){
  background-color: #c89696;
  min-height: 100vh;
  padding-bottom: 100px;
}

:local(.border){
  border: #333;
  border-width: 3px 0px 0px 0px;
  border-style: solid;
  width: 100%;
  margin-bottom: 5px;
}

:local(.mainTitle){
  text-align: center;
  padding: 200px 0px 80px 0px;
  width: 80vw;
  margin: auto;
  font-size: 7vw;
  line-height: 7vw;
}

:local(.title){
  font-size: 3.5rem;
  line-height: 3.7rem;
  padding-left: 0px;
  padding-top: 5px;
}

@media (max-width: 768px) {
  :local(.title){
    font-size: 1.9rem;
    line-height: 2rem;
    padding-left: 15px;
  }
}

:local(.icon){
  text-align: right;
}

:local(.iconHolder svg){
  margin-top: 0.55rem;
  height: 2.5rem;
}
