:local(.title) {
  text-align: center;
  width: 100%;
}

:local(.quotePageContentHolder){
  padding: 0px 15vw;
  text-align: center;
  min-height: 60vh;
}

:local(.quotePageContent){
  width: 100%;
}

:local(.quotePageContent p){
  width: 100%;
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: -0.1rem;
}

:local(p.quotefoot) {
  font-size: 1.8rem;
}

@media (max-width: 768px) {
  :local(.quotePageContent p){
    font-size: 1.2rem;
    line-height: 1.35rem;
  }

  :local(p.quotefoot) {
    font-size: 1rem;
  }

}

:local(.bioHolder){
  min-height: 60vh;
}

:local(.bio) {
  font-size: 1.5rem;
  line-height: 1.6rem;
}

:local(.bio p) {
  font-size: 1.5rem;
  line-height: 1.6rem;
}

@media (max-width: 768px) {
  :local(.bio p) {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

:local(.ymalHolder) {
  margin-top: 100px;
  padding-bottom: 75px;
  background-color: #8bffec;
}

:local(.ymalHolder h2) {
  padding: 20px 0px 10px 0px;
  margin: 0px;
  text-align: center;
  font-size: 1.6em;
  line-height: 1.6em;
  letter-spacing: -0.075em  ;
}

.tagHolder {
  text-align: center;
}

.tag {
  display: inline-block;
  padding: 1px 10px;
  margin: 5px 5px;
  border-style: solid;
  border-width: 2px;
  font-size: 1.3em;
  text-transform: lowercase;
  border-radius: 0px 10px 0px 0px;
}

.tag div {
  display: inline-block;
  width: 20px;
}


:local(.background) {
  padding-right: 4vw;
}


:local(.bgtext p) {
  line-height: 1.2rem;
}


:local(.masonpadding){
  height: 100px;
}


/*** Artist ***/

.topMargin {
  height: 250px;
}
