.button {
  background-color: #333;
  display: inline-block;
  padding: 10px 5px 5px 10px;
  border-radius: 8px;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.leftbutton {
  background-color: #333;
  display: inline-block;
  padding: 10px 10px 5px 5px;
  border-radius: 8px;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.icon svg{
  stroke-width: 2;
  height: 20px;
  margin: 0px 5px 5px 7px;
}
