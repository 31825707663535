.grid-sizerx {
  width: 20%;
}

.grid-sizer, .grid-item {
  width: 48%;
}

.smallitem {
  width: 28%;
}

.normalitem {
  width: 48%;
}

@media (max-width: 768px) {
  .grid-sizer, .grid-item {
    width: 100%;
  }

  .normalitem {
    width: 100%;
  }
}

.b1 {
  width: 24%;
}

.b2 {
  width: 48%;
}

.b3 {
  width: 48%;
}

.b4 {
  width: 100%;
}

.b5 {
  width: 100%;
}

.gutter-sizer {
  width: 4%;
}

.grid-item {
  margin-bottom: 80px;
}

:local(.masonry){
  min-height: 110vh;
  background-color: red;
}


@media (max-width: 768px) {
  :local(.masonry){
    padding-top: 30px;
  }
}
