:local(.mainTitle) {
  text-align: center;
  width: 100%;
  padding-bottom: 20px;
}

:local(.intro) {
  font-size: 1.5rem;
  line-height: 1.6rem;
  padding: 0px 20vw ;
}

@media (max-width: 768px) {
  :local(.intro) {
    font-size: 1.1rem;
    line-height: 1.2rem;
    padding: 0px 20px ;
  }
}

:local(.contentHolder) {
  min-height: 60vh;
}


:local(.padding){
  height: 20vh;
}

@media (max-width: 768px) {
  :local(.padding){
    height: 0vh;
  }
}

:local(.masonry){
  min-height: 110vh;
  margin-bottom: 100px;
}

:local(.arrow){
  width: 3vw;
  height: 3vw;
  margin: 1vw;
}

:local(.text){
  margin: 1.5vw;
}

:local(.name){
  margin-top: 10px;
  font-size: 5.5rem;
  line-height: 5.5rem;
  margin-top: 20px;
  letter-spacing: -0.2rem;
}

:local(.func){
  font-size: 2rem;
  line-height: 2.2rem;
  letter-spacing: -0.05rem;
}

@media (max-width: 768px) {
:local(.name){
    margin-top: 10px;
    font-size: 2.5rem;
    line-height: 2.6rem;
    margin-top: 20px;
  }

  :local(.func){
    font-size: 1.3rem;
    line-height: 1.5rem;
    letter-spacing: -0.05rem;
  }
}

@media (max-width: 768px) {
  :local(.arrow){
    width: 35px;
    height: 35px;
    margin: 20px;
  }
}
