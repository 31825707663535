:local(.landingframe h1) {
  white-space: nowrap;
  /*line-height: 7.8vw;*/
}

@media (max-width: 768px) {
  :local(.landingframe h1) {
    white-space: normal;
  }
}

:local(.arrowright) {
  position: fixed;
  top: 50%;
  right: 10px;
}

:local(.textright) {
  position: fixed;
  top: 50%;
  right: -170px;
  text-align: center;
  width: 500px;
  transform: rotate(90deg);
  transform-origin: bottom;
}

:local(.arrowleft) {
  position: fixed;
  top: 50%;
  left: 10px;
  width: 35px;
}

:local(.textcontent) {
  min-height: 200px;
  padding: 0px 25vw;
  text-align: left;
  font-size: 1.6rem;
  line-height: 1.7rem;
  letter-spacing: -0.1rem;
}

:local(.textcontent p) {
  font-size: 1.5rem;
  line-height: 1.65rem;
  letter-spacing: -0.1rem;
}

@media (max-width: 768px) {
  :local(.textcontent) {
    font-size: 1.1rem;
    line-height: 1.2rem;
    letter-spacing: 0px;
    padding: 0px 30px;
  }

  :local(.textcontent p) {
    font-size: 1rem;
    line-height: 1.1rem;
    letter-spacing: 0px;
  }

  .normaltext div p {
    text-align: left;
    padding-right: 30px;
  }
}

:local(.rtext) {
  overflow: hidden;
}

:local(.legend) {
  margin-bottom: 30px;
}

.quotePageContentHolder {
  padding: 0px 15vw;
  text-align: center;
  min-height: 60vh;
}

@media (max-width: 768px) {
  .quotePageContentHolder {
    padding: 0px 30px;
  }
}

:local(.quotePageContent) {
  width: 100%;
}

:local(.quotePageContent p) {
  width: 100%;
  font-size: 3rem;
  line-height: 3.5rem;
  letter-spacing: -0.1rem;
}

:local(p.quotefoot) {
  font-size: 1.8rem;
}

@media (max-width: 768px) {
  :local(.quotePageContent p) {
    font-size: 1.25rem;
    line-height: 1.35rem;
    letter-spacing: normal;
  }

  :local(p.quotefoot) {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

img.coverimg {
  display: inline-block;
  max-width: calc(70vw - 0px);
  max-height: calc(60vh - 3rem);
}

@media (max-width: 768px) {
  img.coverimg {
    max-width: 100%;
  }
}

:local(p.imgauthor) {
  text-align: left;
  font-size: 1rem;
  line-height: 1rem;
  margin-top: 1rem;
}

.contentHolder {
}

.quote {
}
