:local(.top){
  height: 20vh;
}

:local(.bot){
  height: 20vh;
}

:local(.window){
  min-height: 100vh;
  background-color: #f1f1f2;
}

:local(.contentHolder){
  width: 100%;
  min-height: 60vh;
}

/*Title Page*/

:local(.titlePageContentHolder){
  padding: 0px 15vw;
  text-align: center;
}

:local(.title) {
  text-align: center;
  width: 100%;
}

:local(.contentPageContentHolder){
  padding: 0px 25vw;
  text-align: left;
}

:local(.contentPageContent p){
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: -0.1rem;
}

@media (max-width: 768px) {
  :local(.contentHolder){
    padding: 0px 20px;
    text-align: center;
  }

  :local(.contentPageContent p){
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: normal;
  }
}

:local(.quotePageContentHolder){
  padding: 0px 20vw;
  text-align: center;
}

.wideContent {
  padding: 0px 15vw;
}

@media (max-width: 768px) {
  :local(.quotePageContentHolder){
    padding: 0px 20px;
  }
}


:local(.quotePageContent p){
  width: 100%;
  font-size: 4vw;
  line-height: 4vw;
  letter-spacing: -0.2vw;
}

:local(.coverPageContentHolder){
  padding: 0px 10vw;
  text-align: center;
}

:local(.coverPageContentHolder p){
  width: 100%;
  font-size: 2vw;
  line-height: 2vw;
  /*letter-spacing: -0.1vw;*//**/
}


:local(.arrow) {
  width: 35px;
  opacity: 100;
  transition: opacity 700ms;
}

:local(.top .arrow) {
  margin-top: 90px;
}

:local(.bot .arrow) {
  margin-bottom: 60px;
}

:local(.PageContent p){
  padding: 0px 20vh;
  width: 100%;
  font-size: 2rem;
  line-height: 2.1rem;
  letter-spacing: -0.1rem;
}

@media (max-width: 768px) {
  :local(.PageContent p){
    padding: 0px 5vh;
    font-size: 1rem;
    line-height: 1.1rem;
    letter-spacing: 0rem;

  }
}

:local(.scrolling) {
  opacity: 0;
  transition: opacity 400ms;
}
