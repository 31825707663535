:local(.page){
  background-color: #eba550;
  min-height: 100vh;
  padding-bottom: 100px;
}

:local(.border){
  border: #333;
  border-width: 3px 0px 0px 0px;
  border-style: solid;
  width: 100%;
  margin-bottom: 5px;
}

:local(.mainTitle){
  text-align: center;
  padding: 250px 0px 100px 0px;
  width: 80vw;
  margin: auto;
}

:local(.title){
  font-size: 4.1rem;
  line-height: 4.4rem;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px;
}

@media (max-width: 768px) {
  :local(.title){
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding-left: 15px;
  }
}

:local(.icon){
  text-align: right;
}

:local(.iconHolder svg){
  margin-top: 0.55rem;
  height: 3.5rem;
}

@media (max-width: 768px) {
  :local(.iconHolder svg){
    margin-top: 0.25rem;
    height: 1.75rem;
  }
}
