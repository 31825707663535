:local(.fullscreenButton) {
  margin: 5px 5px;
  padding: 5px 5px;
}


:local(.tag) {
  display: inline-block;
  padding: 4px 6px 1px 3px;
  margin: 5px 5px;
  border-style: solid;
  border-width: 2px;
  font-size: 1.5rem;
  line-height: 1.6rem;
  text-transform: lowercase;
  border-radius: 0px 10px 0px 0px;
}

@media (max-width: 768px) {
  :local(.tag) {
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 3px 8px 0px 6px;
  }
}

:local(.title){
/*  margin-left: auto;
  margin-right: auto;
*/  text-align: left;
  font-size: 1rem;
}

:local(.bgimg){
  transition: background 500ms, width 500ms, height 500ms
}

:local(.subnav){
  padding: 0px 10px;
}


div.remove svg {
  stroke-width: 2.5;
  height: 14px;
  padding: 0px 2px 0px 1px;
  vertical-align: baseline;
}

div.add svg {
  stroke-width: 4;
  height: 15px;
  padding: 0px 1px 0px 1px;
  vertical-align: baseline;
}


@media (max-width: 768px) {
  div.remove svg {
    height: 11px;
    stroke-width: 3;
  }

div.add svg {
  stroke-width: 4;
  height: 13px;
  padding-right: 2px;
}

}
