:local(.logoFull) {
  display: none;
}

:local(.logoSingle) {
  display: none;
}

@media (min-width: 769px) {
  :local(.logoFull) {
    height: 59px;
    display: block
  }
}

@media (max-width: 768px) {
  :local(.logoSingle) {
    height: 40px;
    display: block
  }
}

.logoTour {
  height: 40px;
}

@media (min-width: 769px) {
  .logoTour {
    height: 59px;
    margin-bottom: 6px;
  }
}


.toggleButton svg {
  width: 45px;
  margin: 7px 0px 0px 0px;
}

@media (max-width: 768px) {
  .toggleButton svg {
    width: 31px;
  }
}

.header {
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, .85);
  z-index: 11;
  padding: 15px 20px 0px 20px;
  width: 100%;
}
@media (max-width: 768px) {
  .header {
    padding: 10px 20px 7px 20px;
  }
}


@media (max-width: 768px) {
  :local(.open) {
    height: 100vh;
  }
}

@keyframes opening {
  0% { max-height: 0px; }
  30% {
    margin: 0px 0px 10px 0px;
  }
  100% {
    max-height: 300px;
    margin: 0px 0px 10px 0px;
  }
}

@media (max-width: 768px) {
  @keyframes opening {
    0% { max-height: 0px; }
    100% { max-height: 500px;
      /*border-width: 3px 0px 0px 0px;*/
    }
  }
}

@keyframes baropening {
  0% { height: 0px; }
  15% { height: 3px; }
  50% {
    height: 3px;
    width: 45px;
  }
  100% {
    height: 3px;
    width: 100%;
  }
}

@keyframes barclosing {
  0% {
    height: 3px;
    width: 100%;
 }
  50% {
    height: 3px;
    width: 45px;
  }
  100% {
    height: 0px;
  }
}

$bar_mobile_width: 31px;

@media (max-width: 768px) {
  @keyframes baropening {
    0% { height: 0px; }
    15% { height: 3px; }
    50% {
      height: 3px;
      width: $bar_mobile_width;
    }
    100% {
      height: 3px;
      width: 100%;
    }
  }

  @keyframes barclosing {
    0% {
      height: 3px;
      width: 100%;
   }
    50% {
      height: 3px;
      width: $bar_mobile_width;
      border-color: red;
    }
    100% {
      height: 0px;
      border-color: red;
    }
  }
}


.barholder {
  /*background-color: pink;*/
  height: 3px;
}

.bar {
  height: 0px;
  width: 45px;
  background-color: #333;
}

@media (max-width: 768px) {
  .bar {
    width: $bar_mobile_width;
  }
}

$barspeed: 0.4s;
$opendelay: 0s;

$closespeed: 0.4s;
$closedelay: 0s;

$topopendelay: 0.45s;
.open .bar {
  animation: baropening $barspeed ease-in $opendelay forwards;
}

.closed .bar {
  animation: barclosing $closespeed ease-in $closedelay forwards;
}

.topmenu {
  font-size: 25px;
  border-color: #333;
  border-width: 0px 0px 0px 0px;
  border-style: solid;
  padding: 0px;
  margin: 0px;
  max-height: 0px;
  overflow: hidden;
}

.topopen {
  animation: opening 0.3s ease-in $topopendelay forwards;
}

:local(.menu_holder){
  padding-left: 0px;
  padding-right: 15px;
}

:local(.menu_holder:last-child){
  padding-right: 0px;
}

@media (max-width: 768px) {
  :local(.menu_holder){
    padding-right: 0px;
  }
}

:local(.menuitem){
  border-color: black;
  border-width: 0px 0px 3px 0px;
  border-style: solid;
  text-transform: lowercase;
}

:local(.submenu_holder){
  font-size: 25px;
  padding-left: 0px;
  padding-right: 0px;
  text-transform: lowercase;
}

@media (max-width: 768px) {
  :local(.submenu_holder:last-child){
    padding-bottom: 30px;
  }
}

:local(.rightarrow){
  float: right;
  display: none;
}

:local(.subitem:hover .rightarrow){
  display: inline-block;
}

.plus {
  float: right;
}

.hidePlus {
  display: none;
}

@media (min-width: 768px) {
  .plus {
    display: none;
  }
}


/* Breadcrumbs */

:local(.crumb) {
  display: inline-block;
  padding: 0px 5px;
  margin: 0px 3px;
  border-style: solid;
  border-radius: 0px 10px 0px 0px;
  border-width: 2px;
  height: 25px;
  overflow: hidden;
}

:local(.tag) {
  font-size: 1em;
  line-height: 1.2em;
  display: inline-block;
  padding: 3px 8px 0px;
  margin: 0px 3px;
  border-style: solid;
  border-color: #333;
  border-radius: 0px 10px 0px 0px;
  background-color: #333;
  color: white;

  text-transform: lowercase;

  transition: opacity 300ms ease 0s;
  animation-duration: 600ms;
  animation-direction: reverse;

  animation-name: closeWindow;
  overflow: hidden;
}


.crumbs_holder{
  position: fixed;
  top: 98px;
  padding: 0px 17px;
}

@media (max-width: 768px) {
  .crumbs_holder{
    top: 75px;
  }
}


@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


/*** plus button ***/

.addbutton {
  background-color: transparent;
  border-style: dotted;
}

.addbutton svg {
  height: 13px;
  stroke-width: 5px;
  vertical-align: text-top;
}


/*** search ***/

.topsearch {
  display: inline-block;
  vertical-align: top;
}
