:local(.exploreText) {
  white-space: pre-line;
}
@media (max-width: 768px) {
  :local(.selecttags) {
    font-size: 9vw;
  }
}

.selecttags {
  transition: opacity 1000ms 0.3s;
}

:local(.cloud){
  padding: 0px 20vw;
}

:local(.tag){
  display: inline-block;
  padding: 10px 12px 7px 12px;
  margin: 10px 10px;
  border-style: solid;
  border-color: #333;
  border-radius: 0px 20px 0px 0px;
  border-width: 2px;
  background-color: #333;
  color: #8bffec;

  font-size: 3rem;
  text-transform: lowercase;
  line-height: 3rem;
}

.addbutton{
  background-color: #8bffec;
  border-style: dotted;
}

.search {
  background-color: #8bffec;
  color: #333;
  display: inline-block;
}

.search img {
  padding-left: 1 0px;
  height: 2.5rem;
  vertical-align: top;
}

@media (max-width: 768px) {
  .search img {
    height: 1.2rem;
  }
}

:local(.icon){
  width: 40px;
  height: 40px;
  display: inline-block;
}

:local(.icon svg){
  padding-bottom: 10px;
  transition: all .3s ease-in;
}

:local(.rotate svg) {
  transform: rotateZ(45deg);
}

@media (max-width: 768px) {
  :local(.tag){
    font-size: 1.2rem;
    line-height: 1.3rem;
    padding: 5px 5px 2px 5px;
    border-radius: 0px 10px 0px 0px;
    margin: 5px 5px;
  }

  :local(.icon){
    width: 15px;
    height: 15px;
    display: inline-block;
  }

  :local(.icon svg){
    padding-bottom: 5px;
  }
}


:local(.enterHolder){
  height: 20vh;
  opacity: 0;
  transition: opacity 1000ms 0.3s;
}

.showButton {
  opacity: 1;
}


:local(.enter) {
  display: inline-block;
  background-color: #f1f1f1;
  padding: 8px 10px 6px 12px;
  border-radius: 8px 8px 8px 8px;
  margin-bottom: 60px;
}

:local(.text){
  display: inline-block;
  letter-spacing: -0.05em;
  padding-right: 14px;
  font-size: 2rem;
  line-height: 2rem;
}

:local(.arrowHolder){
  font-size: 2.5rem;
  line-height: 2rem;
  display: inline-block;
}

:local(.visible){
  opacity: 100;
}


:local(.hidden) {
  opacity: 0;
  transition: opacity 2000ms 0.3s;
}

.void {
  display: none;
}

.unvoid {
  display: inline-block;
}

:local(.showing) {
  opacity: 100;
  transition: opacity 2000ms 0.3s;
}

:local(.hiding) {
  opacity: 0;
  transition: opacity 2000ms 5s;
}

.cloudsearchholder {
  margin-top: 50px;
}

@media (max-width: 768px) {
  .cloudsearchholder {
    margin-top: 20px;
  }
}
