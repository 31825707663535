.hgrid-sizer {
  /*//width: calc((96% - 40px) / 4 )*/
  width: 23%;
}

.hg1 {
  width: 24%;
}

.hg2 {
  width: 49%;
}

.height1 {
  /*min-height: 30vh;*/
  min-height: 40vh;
}

.height2 {
  min-height: 60vh;
  /*min-height: 50vh;*/
}
@media (max-width: 768px) {
  .height1 {
     min-height: 30vh;
  }

  .height2 {
    min-height: 30vh;
  }
}


.hg1 .rlt, .hg2 .rlt {
  border-radius: 6vw 0px 0px 0px;
}

.hg1 .rrt, .hg2 .rrt {
  border-radius: 0px 6vw 0px 0px;
}

.hg1 .rlb, .hg2 .rlb {
  border-radius: 0px 0px 6vw 0px;
}

.hg1 .rrb, .hg2 .rrb {
  border-radius: 0px 0px 0px 6vw;
}

.hgrid-item {
  margin-bottom: 1vw;
  min-height: 50vh;
}

.hgutter-sizer {
  width: 2%;
}

@media (max-width: 768px) {
  .hgrid-sizer, .hgrid-item {
    width: 100%;
  }

  .hg1, .hg2{
    width: 100%;
  }

  .hgrid-item {
    margin-bottom: 2vw;
    min-height: 0vh;
  }
}
