:local(.masonry){
  min-height: 110vh;
  background-color: white;
}


@media (max-width: 768px) {
  :local(.masonry){
    padding-top: 30px;
  }
}

.solo {
  min-height: calc(100vh - 500px)
}

@media (max-width: 768px) {
  .solo {
    min-height: calc(10vh)
  }
}
