:local(.stdwindow){
  background-color: #ff5032;
  padding-top: 200px;
  min-height: 100vh;
}

:local(.rightside){
  border-style: solid;
  border-width: 0px 0px 0px 3px;
  margin-bottom: 100px;
  padding: 30px 20px 20px 20px !important;
  min-height: calc(100vh - 300px);
}

:local(h2.event){
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 0px;
}

:local(.eventconten){
  margin-top: 30px;
  padding-right: 20px;
  margin-bottom: 40px;
}

:local(.eventButton) {
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
}
