@use './defaults';

$link-color: defaults.$black;
$link-hover-decoration: none;
$link-hover-color: defaults.$black;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 940px,
  xl: 1540px,
);

$font-family-sans-serif: HelveticaNeueeTextPro-Roman;

@import "~bootstrap/scss/bootstrap";

.italic {
  font-family: HelveticaNeueeTextPro-Italic;
}
.bold {
  font-family: HelveticaNeueeTextPro-Bold;
}
.bold_italic {
  font-family: HelveticaNeueeTextStd-BoldIt;
}

strong {
  font-weight: normal;
  font-family: HelveticaNeueeTextPro-Bold;
}

body {
  color: defaults.$black;
}

h1 {
  font-family: HelveticaNeueeTextPro-Bold;
  font-size: 5rem;
  letter-spacing: -1.5px;
  line-height: 5.1rem;

  // font-size: 7vw;
  // letter-spacing: -0.3vw,
}

h1.secundair {
}

@media (max-width: 768px) {
  h1 {
    font-size: 3rem;
    letter-spacing: -1.3px;
    line-height: 3.1rem;
  }

  h1.secundair {
    font-size: 2rem;
    letter-spacing: -1.3px;
    line-height: 2.1rem;
  }
}

h2 {
  font-family: HelveticaNeueeTextPro-Bold;
  font-size: 5vw;
  letter-spacing: -0.1vw;
}

p {
  font-size: 1rem;
}

iframe {
  border: 0;
}

.form-control:focus {
  background-color: transparent;
  box-shadow: none;
  border-color: #333;
}

#mc-embedded-subscribe {
  background-color: defaults.$black;
  color: white;
  border: 0px;

  padding: 10px 10px 8px 12px;
  border-radius: 8px;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.IEE {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .IEE {
    display: block;
  }

  .IEE p {
    width: 80%;
    padding: 100px 10% 100px 10%;
  }

  #app {
    display: none;
  }
}
