.button {
  margin: 5px;
  color: #fff;
  background-color: #333;
  display: inline-block;
  padding: 10px 5px 5px 10px;
  border-radius: 0px 8px 0px 0px;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

:local(.leftbutton) {
  margin: 5px;
  color: #fff;
  background-color: #333;
  display: inline-block;
  padding: 10px 10px 5px 5px;
  border-radius: 8px;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

@media (max-width: 768px) {
  .button {
    padding: 10px 5px 5px 10px;
    border-radius: 0px 8px 0px 0px;
    font-size: 1rem;
    line-height: 1.2rem;
  }
}
